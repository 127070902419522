import React from "react";

export default function CeresDrugStoreSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 5.22" className="w-full dark-txt">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class="cls-1"
            fill="#494b4e"
            d="M26.58,1.74V1.61h0V1.25h0V1h-.11V.87h0V.51h0V.38h-.13V.51h-.11V.62H26c0-.1,0-.13-.12-.11h-.38V.38h-.61V.25h-.63V.37h0V.25h-1V.37H23V.51h-.12c-.13,0-.13,0-.13.11h-.24V.51h-.11c0-.14,0-.14-.14-.13h-.74V.51h0V.38h-.13V.51h-.11V1h.5c.09,0,.13,0,.11.11h0V2.37h-.25v.12h-.11v.24h.11v.12h.25v.49h0V4h0V4.1h-.11v.13h-.5v.62h.86V4.73h.13V4.61h.38v.12h.11v.13h.49c.14,0,.14,0,.14-.13,0,.13,0,.13-.14.13H23V5h2.13V4.86h.36V4.73h0V4.61h0v.12h.14V4.61h0v.11h.49v.13h.25V4.48h0V4.35h.13V4.23h0V4.1h0V4h.11V3.61h-.11V3.49h0V3.36h-.13V3.25h-.25V3.12h-.63v.12h-.11v.11H25.2c-.09,0-.13,0-.11.12v0h0v.12h0V4h0V4.1h.11v.13h-.11v.1h-.38v.13h-1V4.35h-.51v-.1h-.11V4.1h0V3h1.75V2.87h-.13V2.73h0V2.62h0V2.49h.13V2.37H23.21c-.13,0-.12-.05-.12-.13h0v-1h.11V1.12h0V1h.24V.87h.38V.76h.38V.87h.12V.76h.38V.87h.49V1h.24v.12h-.11v.13h-.13v.11h0v.51h0V2h.13v.13h.25v.11H26V2.13h.27c.1,0,.12,0,.11-.14s.15,0,.14-.11h0V1.75Zm-4.74.64v0h0Zm4.49,1.23h-.11v0Zm-.11-.12Zm0,.5h-.14V4ZM25,4.61h0Zm-.63-2h0c0-.07,0-.1,0-.12S24.33,2.55,24.33,2.62ZM25,.75V.62h0ZM25.7,1h0s0,.05,0,.07S25.71,1,25.7,1Zm.76.25h0Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M53.52,4.37c-.05-.06-.16,0-.24,0H52.9V4.24h-.11V4.1h-.12V3.85h0V3.61h-.11V3.25h-.13V3.11h-.25V3h-.11V2.87h.36V2.73h.24V2.62h.13V2.49h.11s0-.11,0-.1.1-.07.11-.14V2.13h.11v-1H53V1h0V.87H52.9c0-.1,0-.13-.11-.11h0V.62h-.13V.51h-.24c0-.1,0-.15-.13-.14H48.67V.51h-.13V.62h0V.75h-.25V.62h0V.51h-.11V.38h-1v.73h.49v.13h.13v.49h-.13v.73h-.25c-.11,0-.12,0-.11.14h0v.11h0v.13h.36V3h0v.13h0v1h0v.13h-.11v.1h-.5V5h1.12V4.86h0V4.73h.11V4.61h.13v.11h0v.13h.1c0,.12.07.11.14.12h1V4.35h-.49V4.23H49V3.11C49,3,49,3,49.17,3h1.5v.13h.25v.13H51v.11h0v.25h.12v.13h0V4h.11V3.85h0V4.1h0v.38h.12V5h.24V4.86c.11,0,.11-.05.11-.13h.13c0,.13,0,.13.13.13s0,.13.12.11h1.26c.09,0,.12,0,.11-.1a2.26,2.26,0,0,1,0-.26C53.53,4.53,53.56,4.42,53.52,4.37Zm-1-3.74h0Zm-3.62,3v0a.15.15,0,0,1,0-.1A.15.15,0,0,0,48.92,3.61ZM51.53,1Zm-.74,1.86h0Zm.74,1.49v0c.07,0,.1,0,.12,0S51.6,4.35,51.53,4.35Zm.25-2.47V2h-.1v.13h0v.12h-.24v.13H49V1.12h.13V1h2.24v.11h.11c0,.16,0,.12.13.13h.12V1.12h0v.25h0v.52Zm.13,2.74h0v0Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M61.52.37h0v0h-.11V.75h0V.38h-.77V.51h0V.62H60.4V.51h-.11V.38H59.4V1H60v.11h.12V2.37H60v.11h-.24c-.09,0-.13,0-.12.1a.65.65,0,0,1,0,.14c0,.08,0,.16.11.14H60v.87h0v.11h0V4H59.9V4.1h0v.13h-.25v.1h-.13c-.12,0-.12,0-.12.13H59v.13H57.65V4.48H57.4V4.35h-.24V4.24H57V4.1h0V4h-.11V3c0-.1,0-.13.11-.12h.25V2.49h-.12v-.1h-.24V1.25H57V1.12h0C57,1,57,1,57.13,1a.66.66,0,0,0,.14,0h.38V.38H56.53V.51h-.11V.76h-.24V.51h0V.38h-1V.87c.07,0,.1,0,.12,0s-.05,0-.12,0V.38h-.24V1h.49a.25.25,0,0,1,0-.11.25.25,0,0,0,0,.11c.08,0,.15,0,.14.12h0c0,.42,0,.83,0,1.25v.11h-.25c-.13,0-.13,0-.13.15v.1c0,.13,0,.13.13.14h.25V4h.11V4.1h0v.13h.13v.11h.11v.13h0v.13h.26v.11h.11v.13h.24V5h.24V5.1h.5v.11h1.88V5.11h.49V5h.25c0-.09,0-.12.11-.11h.14V4.73h.13V4.61h.11V4.48h.14V4.35h.11V4.23h0V4.1h.13V4h0V3.85h.11v-1h.24V2.49h-.25v-1h0V1.12H61V1h.62V.51C61.65.38,61.65.38,61.52.37ZM60,2.62h0v0ZM55.78,3.74Zm1.37.74h0v0Z"
          />
          <polygon
            class="cls-1"
            fill="#494b4e"
            points="61.02 1.12 61.02 1.12 61.02 1.12 61.02 1.12"
          />
          <path class="cls-1"
          fill="#494b4e" d="M38.93,4.86h0V5h.13V4.86Z" />
          <path class="cls-1"
          fill="#494b4e" d="M26.22,3.24V3.11h-.13v.12Z" />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M33.33,3.36V3.25H33.2V3.11h0V3h-.11V2.87c-.11,0-.15,0-.14-.14h-.13V2.62h-.24V2.49H32.2V2.37h-.76V2.24h-1V2.13H30V2h-.13V1.88h-.11V1.74h0V1.61h-.13V1.37h.12V1.25h0V1.12h.11V1h0C30,1,30,1,30,.87h.11V.76h.26V.63h.6c0-.12.06-.12.14-.11h0c-.08,0-.16,0-.14.11h.38V.75h.24V.87h.24V1H32V.87h0v.25h-.13v.13h0v.11h-.11v.37h.11v.13H32V2h.12v.12h.74V2h0v.13h0c.1,0,.16,0,.14-.14h.13V1.88h0V1.75h.11V1.61h0V1.25h0V1.12h-.11V1h0V.87H33V.76h0V.63h-.13V.38h-.25V.51H32.2V.38h-.63v0h.14V.25H30.2V.37h-.49V.51h-.38V.62h-.24V.76H29V.87h-.13V1H28.7v.12h0v.13h-.11v.12h0V1.5h-.13v.62h.13v.24h0c0,.08,0,.14.11.12v.13h.14v.11H29v.13h.25V3h.24v.13h1v.12h.87v.11h.37v.13H32v.12h0v.13h.11v.49H32v.11h-.13v.13h-.11v.13h-.26v.11h-1.1v-.1h-.51V4.48h-.25V4.35h-.11V4.23h.11V4.1h0V3.61h0c0-.09,0-.14-.12-.12V3.36h-.14V3.25H28.7v.11h-.11c-.11,0-.15,0-.14.14h0v.12h-.1v.13h0v.11H28.2v.38h.13v.12h0v.13h.12v.13h.13v.11h.11v.13h0V5H29c0-.09,0-.13.12-.11h.24V5h.25V5.1h.76v.11h1V5.11h.87V5h.25V4.86h.24V4.73h.11V4.62h.13V4.48h.13V4.35h-.14s0-.08,0-.1,0,0,0,.1h.25V4.23h0V4.1h.12V4h0V3.36Zm-1.26-2H32v0ZM29,1h0V1Zm.38.63s.05,0,.11,0a.11.11,0,0,0,0-.08.11.11,0,0,1,0,.08C29.39,1.61,29.36,1.61,29.34,1.63Zm.37,1.23h0Zm-.88,1.87v0ZM29.08,4S29,4,29,4s.05,0,.1,0h0Zm.13-.5Zm.25.61v0ZM32,4.86a.31.31,0,0,0,0-.12.31.31,0,0,1,0,.12h0Zm1.13-1.5v0h0Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M61.13,2.86h.14V2.63c0-.14,0-.14-.13-.15h0v.37Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M70,2.62h-.11V2.49h-1v.13h-.11v.11h0v.13h-.24V2.73h-.13V2.62h-.11V2.49H67.14v.62h.6v.12h.13V4.1h-.13v.13h-.11v.11h-.25v.13h-.25v.13H65.89V4.48h-.24V4.35h-.25v-.1h-.13V4.1h-.11V4h0V3.86H65V3.74h0V3.49H64.9V2.87h.36V2.73h0V2.48h0V2.37H64.9V2H65V1.61h0V1.37h.13V1.25h.11V1.12h0V1h.13V.87c.08,0,.16,0,.13-.11h.12V.63h.24V.51H67V.62h.38V.75h.11V.87h.13c.11,0,.11,0,.11.14h0v.25h-.11v.36h.11v.14h0v.14h.13V2H68v.13h.26v.11h.36V2.13H69C69,2,69,2,69.12,2V2c.09,0,.16,0,.14-.12s0-.13-.14-.13a1.08,1.08,0,0,0-.18,0s-.07,0-.06-.05,0-.09-.11-.09h-.14V1.5c0-.14,0-.14.13-.14h0c0-.1,0-.13.12-.12s0-.11,0-.13a.25.25,0,0,1,.19,0c.05,0,0,.08,0,.12s.16,0,.14.11.13,0,.11-.11V1.12c-.1,0-.13,0-.11-.12V.87h-.14V.76H69V.62h-.14a.75.75,0,0,1-.15,0,.75.75,0,0,0,.15,0H69V.25h-.13c-.12,0-.13,0-.12.13h-.13V.51h-.25V.38h-.25V.25h-.49V.13h-.75V0h-.62V.13h-.87V.24h-.5V.37h-.24V.51h-.13c-.09,0-.13,0-.11.11h-.13V.75h-.11V.87H64V1h-.12v.12h-.11v.13h0v.11h-.13V1.5h-.11v.11h0v.14h0v.14h-.13V2h0v.38H63v.11s-.1,0-.12,0a.3.3,0,0,0,0,.21s.08,0,.12,0v.13h.36v.49h0V3.6h.13v.13h0v.11h.12V4h.13V4.1h0c0,.08,0,.16.12.14v.11H64v.13h.13v.13h.24v.11h.24v.13h.24V5h.35V5.1h.87v.11h.76V5.11h.74V5H68V4.86h.27V4.73h.24v.13h.24V5H69V5.1h.25v-.5h0c0-.37,0-.74,0-1.12V3.36a.66.66,0,0,0,0-.14c0-.09,0-.12.11-.1h.52V3H70Zm-2.11.49h0v0Zm.74-1.5Zm-3-1.1a.31.31,0,0,1,0-.12.31.31,0,0,0,0,.12.1.1,0,0,0-.1,0A.1.1,0,0,1,65.64.51Zm0-.28a.15.15,0,0,1-.1,0h0A.15.15,0,0,0,65.62.23Zm-.1.52v0h0Zm-.38.12h0Zm-.25.88h0v0ZM64.15,1Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M45.55,2.48H45.3V2.37h0V2.12h-.11V2h0V1.88h0V1.75h-.13V1.61h0V1.5h-.12V1.36h0V1.25H44.8V1.12h-.13V1h0c0-.07,0-.16-.11-.13,0-.12-.06-.11-.14-.12h-.11V.62h-.14V.75h0V.62h0V.51h-.36V.38h-.63V.51h0v.61h0v.13h.25v.11c.12,0,.12,0,.12.14s0,.11.13.11h0v.14h0v.14h.13v.24h0v.36h-.38v.13h0v.11h0v.13h.38v.74h-.13v.13h0v.11h-.12V4c-.09,0-.13,0-.11.12H43.3v.13h-.11v.11h-.13V5h.25V4.86c-.12,0-.13-.05-.12-.13,0,.08,0,.15.12.13V5h.24V4.86h.27V4.73h.25v-.1h.24V4.48h.11V4.35h.13V4.23h.11V4.1h.13V4h.11V3.85h0V3.74h.13V3.61h0V3.49h.13V3.36h0V3.11h0V3h.1V2.87h.25c.13,0,.13,0,.13-.14V2.62C45.68,2.49,45.68,2.49,45.55,2.48Zm-1.63.77V2.87h0Zm1,.11Z"
          />
          <path class="cls-1"
          fill="#494b4e" d="M2,4.73v.13h.11V4.73Z" />
          <polygon
            class="cls-1"
            fill="#494b4e"
            points="42.8 0.38 42.8 0.38 42.8 0.38 42.8 0.38"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M19.85,4.63v-.4h-.51V4.1h-.11V4h-.13V4.1h0V3.74h0V3.61H19V3.36h0V3.24h0c0-.16,0-.14-.13-.14h0V3h-.36V2.87h.24V2.74H19V2.62h.12c.13,0,.13,0,.13-.13h0q.13,0,.12-.12c.09,0,.15,0,.13-.12h0V2.12h.13V2h0V1.36h0V1.12h-.13V1h0c0-.13,0-.13-.14-.13h0c0-.09,0-.13-.11-.11h0V.63h-.13V.51h-.5V.38h-3.1V.51h-.13V.62h-.13V.75H15.1V.62h0V.51H15c0-.13,0-.13-.14-.13H14V1h.49v.11h0v.13h.12c0,.37,0,.75,0,1.12h-.25c-.08,0-.15,0-.14.11h0v.24h.24v.12h.12v.62h-.12V4h0V4.1h-.11v.13h-.51v.38h0v.25h.87V4.73h.14c.09,0,.12,0,.11-.12h0V4.48h.25v.13h0v.12h.13v.13h.87V4.48h.11V4.24h-.49V4.1h-.11V3.36h.1V3h1.61v.13h.13v.14h0v.12h.11v.25h0v.14h0v.11h.12V4.1h0v.25H18v.38h.27v-.1h.24v.11h.11v.13h1.26V4.73h0ZM19,.87h0c0-.05,0-.08,0-.09S19,.82,19,.87ZM15.6,2.24v.13h0Zm0,.87v0h0Zm2.88,1.37h0Zm0-3.12c0,.14,0,.14-.13.14v.11h0v.27h0V2h-.11v.13H18.1v.1h-.24v.13h-2V2.24h0V1H18v.11h.18c.07,0,.1,0,.09.08v.05h0v.11h.11V1.5h0c.12,0,.12,0,.13-.14V1.27l.11,0-.11,0Zm.25,3.25h0Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M42.79,4.1V4h-.1C42.67,4.08,42.71,4.11,42.79,4.1Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M5.5,1.25h0V1.12H5.36V1h0V.87H5.25V.38H5V.51H5a.09.09,0,0,1,.09,0A.09.09,0,0,0,5,.51H4.87V.62H4.62V.51H4.37V.38H4.12V.51h0V.38H4V.25H3.63V.37h0V.25H2.37V.37H1.88V.51H1.63V.62s.08,0,.1,0-.05,0-.1,0H1.49c-.11,0-.12.05-.11.13H1.25V.87H1.13C1.13,1,1.13,1,1,1v.11H.89v.13H.75v.11H.64V1.5h0v.11H.5v.14h0v.14h0V2H.37v.14h0v.12h0v.13H0v.25c0,.09,0,.13.12.11H.37v.38h0v.25H.5v.13h0v.12H.63v.13h0v.11H.75V4h0V4.1H.88v.13H1v.12h.24v.13h.13v.13h.25v.1h.49v.13H4V4.73h.25v-.1h.24V4.48h.13c.1,0,.16,0,.13-.13s.13,0,.12-.11h0c.07,0,.1,0,.09-.08V4.1c.1,0,.13,0,.12-.11s.14,0,.13-.13h0V3.74h.11V3.6H5.25v0h.11V3.49h.13V3.36h0V3.25H5.36V3.11H4.24v.13H4.13v.24h0v.12H4v.13H4v.11H3.88V4H3.74V4.1H3.63v.13H3.38v.1H2.51v-.1H2.26V4.1H2.12V4H2V3.86H1.88V3.74h0V3.49H1.74V3.36h0V3.11h0V2.73h0V2.62h0v.11H2V2.62H2V2.37H1.74V2h0V1.88h0V1.75h.13V1.61h0V1.5H2V1.37H2V1.25h.11V1.12h.13V1h.11V.87h.25V.76H3.74V.87h.13C4,.86,4,.89,4,1V1h.13v.35H4V1.5H4V2h.13v.13h0c0,.09,0,.14.12.12h.13v.13h.74V2.24h.13V2.13h.11V2h.13V1.74h0v-.5Zm-4.87,1h0Zm.12,1.5Zm4.37-.49v.11c.06,0,.09,0,.11,0s-.05,0-.11,0Zm-.4.11a0,0,0,0,1,0,0,0,0,0,0,0,0,0s0,0,0,0S4.7,3.36,4.72,3.36ZM2.12.62Zm2,.88h0v0Zm.25.24a.25.25,0,0,0-.12,0,.25.25,0,0,1,.12,0v0Zm.12.14h0c-.06,0-.09,0-.11,0S4.43,1.89,4.49,1.88Zm0,.36ZM5,2.12H5c-.07,0-.15,0-.14-.09,0,.12.07.09.14.09H5A.28.28,0,0,1,5,2,.28.28,0,0,0,5,2.12Zm.25-.24h0v0Z"
          />
          <path class="cls-1"
          fill="#494b4e" d="M18.73.51V.38h-.13V.51Z" />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M42.93,1V.51H42.8V.38h-.11V.51H40.55V.62h-.11c0,.14,0,.14-.14.13V.63h-.11c0-.08,0-.13-.11-.11h-1V.76h0V1h.11c0-.07,0-.1,0-.11s0,0,0,.11h-.11v.11h.49v.13h.11v.12h0V2.49h-.36v.13h0v.24h.25V3h.11v.51h-.11V4.1h0v.13h-.13v.1h-.49v.51h.13V5h3.49v.13h.25V4.23h-.25v.11H40.93V4.23h0V4.1H40.8V3h.13V2.87h.25V2.73h.13V2.62h-.13V2.49h-.25V1.25H41V1.12h1.63v.13h.24V1ZM39.31.87Zm.37,3.23h0ZM40.3.76a.13.13,0,0,1,0,.09.13.13,0,0,0,0-.09Zm.38,3.09h0a.37.37,0,0,0,0,.11A.37.37,0,0,1,40.68,3.85Zm.12-2.48Zm1-.5h0Z"
          />
          <path
            class="cls-1"
            fill="#494b4e"
            d="M12.48,1.5V1.36h0V1h-.11V.87h0V.76h0V.38h-.25V.51h-.49V.38H11V.25h-.24V.37h0V.25h-1V.38h0V.25H9.24V.37H9V.51H8.74V.62H8.49V.51H8.36c0-.13,0-.13-.12-.13H7.37V.51H7.25V.87h.11V1h.37c.1,0,.13,0,.12.11h0V2.37H7.5v.12h0v.24h.24v.12h.11v1H7.75V4h0V4.1H7.61v.12H7.12v.5H8.24V4.61h0V4.48h.19s.06,0,.06.06v.07h.13v.1h.25v.13h.36V5h1.5V4.86h.36V4.73h.38V4.62h.25c.13,0,.13,0,.13.1H12v.13h.13V4.73h.1V4.35h0V4.23h0V4.1h.13V4h0V3.85h0V3.6h0V3.49h0V3.36h-.13V3.25h-.11V3.12H12V3h-.5v.13h-.25c0,.12,0,.12-.13.13s0,.13-.11.11h0v.25h-.12v.24H11V4h0v.24h-.24v.1H9.37V4.24H9.12V4.1H9V3h1.74V2.73h-.13V2.49h.13V2.37h0V2.24H9.12C9,2.24,9,2.25,9,2.13h.12v-1h0V1h.24V.87h0v0h.37V.76h1V.87h.36V1h.13v.11h0v.13H11.1v.11H11v.37h.11v.13h0V2h.26v.13h.74V2h.11V1.88h.12V1.74h.11V1.61h0ZM8,.76V.51H8V.76H8ZM11.1,3.85h0v0Zm-.86.63Zm0,.38Zm.36-2v0h0Zm0-2.35s0,0-.07,0,.05,0,.07,0Zm.77.85a.25.25,0,0,0-.12,0,.25.25,0,0,1,.12,0v0Zm0-.49h0c0-.05,0-.08,0-.1S11.36.82,11.37.87Zm.85.87Z"
          />
          <polygon
            class="cls-1"
            fill="#494b4e"
            points="10.74 2.73 10.74 2.73 10.74 2.73 10.74 2.73"
          />
        </g>
      </g>
    </svg>
  );
}
